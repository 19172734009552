import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RequestorService extends GlobalService{

  constructor(private _http: HttpClient) {
    super(_http);
    this.serviceUrl = '';
  }

  planSettings: any;

  patchDadosEscritorio(id: number, data: any){
    return this.patch(id, data, 'requestor');
  }

  patchOffice(id: number, data: any){
    return this.patch(id, data, 'office');
  }

  patchDadosMinhaConta(id: number, data: any){
    return this.patch(id, data, 'requestor');
  }

  patchDadosSeguranca(id: number, data: any){
    return this.patch(id, data);
  }

  getIdContratante(){
    return this.get(null, 'requestor');
  }

  getContratante(id: number){
    return this.getById(id, 'requestor');
  }

  getContratantePreferencias(){
    return this.get(null, 'requestor-preferences');
  }

  patchContratantePreferencias(id: number, data: any){
    // return this.patch(id, data, 'requestor-preferences');
    return this.http.patch(`${this.baseUrl}requestor-preferences/${id}/`, data, { headers: this.getHeadersFormData() })
  }

  postContratantePreferencias(data: any){
    return this.http.post(`${this.baseUrl}requestor-preferences/`, data, { headers: this.getHeadersFormData() })
  }

  getPrazos(){
    return this.get(null, 'deadline-types');
  }

  getRequestorList(){
    return this.get(null, 'office/requestors');
  }

  getPermissions(){
    return this.get(null, 'office/permissions/list');
  }

  patchUserPermissions(data: any){
    return this.patchNone( data, 'requestor/update-permission');
  }

  deleteUserFromOffice(id: number){
    return this.patchNone({
      requestor_id: id
    }, 'office/delete_office_member');
  }

  createUserInOffice(data: any){
    return this.post(data, 'requestor/create');
  }

  getPlanSettings(){
    return this.get(null, 'plan-settings');
  }

  sendService(data: any){
    return this.post(data, 'new-packages');
  }

  getServiceTypes(){
    return this.get(null, 'services/?sub=True');
  }

  getAreas(){
    return this.get(null, 'tags-subarea');
  }

  getMainSpecialties(){
    return this.get(null, 'main-specialties');
  }

  patchImage(formData: any, id: number){
    return this.http.patch(`${this.baseUrl}update-user/${id}/`, formData, { headers: this.getHeadersFormData() })
  }
}
